
import Btn from '../../Molecules/Btn'
import NewsItem from './NewsItem'

import React, { useContext, useRef, useState, useMemo } from 'react'
import { RContext } from '../../RContext'
import { DesignContext } from '../../DesignContext';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faCircle } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { Card, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { reactLocalStorage as Ls } from 'reactjs-localstorage';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { deleteNews, updateNews } from "../actions/ajouterunjouer"
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Bars } from 'react-loader-spinner'
import PdfUrlViewer from './PdfUrlViewer';
import { GlobalWorkerOptions } from "pdfjs-dist";

// console.log(object)
GlobalWorkerOptions.workerSrc = "https://d2v5g.csb.app/pdf.worker.js";


function Reglement({ type = undefined }) {


    const dispatch = useDispatch();
    let { sex, category } = useParams();

    const { design } = useContext(DesignContext);
    const { isMedium, isSmall, isLarge, notifier, isLoggedIn } = useContext(RContext)
    const [key, setKey] = useState(0);
    let history = useHistory();

    const iframeRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = 57; // Total pages in the PDF
    const windowRef = useRef();
    const [scale, setScale] = useState(isSmall ? .65 : 1.2);
    // Function to navigate to a specific page
    const scrollToItem = (pageNum) => {
        windowRef.current && windowRef.current.scrollToItem(pageNum - 1, "start");
    };

    const goToPage = (pageNum) => {
        // setCurrentPage(pageNum);

        // // Change the key to force iframe re-render
        // setKey((prevKey) => prevKey + 1);

        scrollToItem(pageNum)

    };

    const pages = {
        "sections": [
          {
            "title": "العنوان الأول : أحكام عامة",
            "content": [
              {
                "page": 2,
                "title": "العنوان الأول : أحكام عامة"
              }
            ]
          },
          {
            "title": "العنوان الثاني : العضوية في الجامعة",
            "content": [
              {
                "page": 3,
                "title": "العنوان الثاني : العضوية في الجامعة"
              }
            ]
          },
          {
            "title": "العنوان الثالث : الهياكل الجامعية",
            "content": [
              {
                "page": 4,
                "title": "العنوان الثالث : الهياكل الجامعية"
              },
              {
                "page": 5,
                "title": "الباب الأول: الجلسة العامة"
              },
              {
                "page": 5,
                "title": "القسم الأول : أحكام عامة"
              },
              {
                "page": 5,
                "title": "القسم الثاني: الجلسة العامة العادية"
              },
              {
                "page": 6,
                "title": "القسم الثالث: الجلسة العامة الخارقة للعادة"
              },
              {
                "page": 8,
                "title": "القسم الرابع: الجلسة العامة الانتخابية الاستثنائية"
              },
              {
                "page": 8,
                "title": "الباب الثاني: المكتب الجامعي"
              },
              {
                "page": 8,
                "title": "القسم الأول :المهام والصلاحيات"
              },
              {
                "page": 10,
                "title": "القسم الثاني: تركيبة المكتب الجامعي"
              },
              {
                "page": 11,
                "title": "القسم الثالث : شروط الترشح لعضوية المكتب الجامعي"
              },
              {
                "page": 12,
                "title": "القسم الرابع: شروط تقديم القائمات المترشحة للانتخابات"
              },
              {
                "page": 14,
                "title": "القسم الخامس: رئيس الجامعة"
              },
              {
                "page": 14,
                "title": "القسم السادس: فقدان العضوية وحالة الشغور بالمكتب الجامعي"
              },
              {
                "page": 15,
                "title": "الباب الثالث: هياكل التصرف"
              },
              {
                "page": 15,
                "title": "القسم الأول: الإدارة الفنية"
              },
              {
                "page": 15,
                "title": "القسم الثاني: الكتابة العامة"
              },
              {
                "page": 16,
                "title": "القسم الثالث: اللجان الجامعية"
              },
              {
                "page": 16,
                "title": "القسم الرابع: الرابطات"
              }
            ]
          },
          {
            "title": "العنوان الرابع: التأديب وفض النزاعات",
            "content": [
              {
                "page": 16,
                "title": "القسم الأول: أحكام عامة"
              },
              {
                "page": 17,
                "title": "القسم الثاني: الاختصاص في المادة التأديبية"
              },
              {
                "page": 17,
                "title": "القسم الثالث: الاختصاص في مادة فض النزاعات"
              },
              {
                "page": 18,
                "title": "القسم الرابع: التعهد التلقائي للمكتب الجامعي"
              },
              {
                "page": 19,
                "title": "القسم الخامس: لجان التأديب في مجل مكافحة المنشطات"
              }
            ]
          },
          {
            "title": "العنوان الخامس: الموارد المالية ومسك الحسابات واشراف الدولة",
            "content": [
              {
                "page": 19,
                "title": "الباب الأول: الموارد المالية"
              },
              {
                "page": 20,
                "title": "الباب الثاني: مسك ومراقبة الحسابات"
              }
            ]
          },
          {
            "title": "العنوان السادس: الاشراف",
            "content": [
              {
                "page": 20,
                "title": "العنوان السادس: الاشراف"
              }
            ]
          },
          {
            "title": "العنوان السابع: أحكام مختلفة",
            "content": [
              {
                "page": 21,
                "title": "العنوان السابع: أحكام مختلفة"
              }
            ]
          },
          {
            "title": "العنوان الثامن: أحكام انتقالية",
            "content": [
              {
                "page": 21,
                "title": "العنوان الثامن: أحكام انتقالية"
              }
            ]
          }
        ]
      }
      


    // Render buttons for each page
    const AccordionComponent = ({ chapters, goToPage }) => {
        return (
            <Accordion defaultActiveKey="0" style={{ direction: 'rtl', textAlign: 'right' }}>
                {chapters.map((chapter, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header

                            style={{ display: 'flex', justifyContent: 'space-between', direction: 'rtl' }}
                        >
                            {/* hello */}
                            {chapter.title}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Accordion.Header>
                        <Accordion.Body>
                            {chapter.content.map((item, itemIndex) => (
                                <div key={itemIndex} style={{ marginBottom: '10px' }}>
                                    <Button
                                        variant="link"
                                        onClick={() => goToPage(item.page)}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        {`${item.title}`}
                                    </Button>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        );
    };



    return (
        <div>
            <h1 style={{ textAlign: "center", marginTop: 50, marginBottom: 10 }}>RÈGLEMENT INTÉRIEUR</h1>
            <div style={{
                display: "flex",
                flexDirection: "row-reverse",
                flexWrap: isSmall ? "wrap" : "nowrap",
                width: "95vw",
                minHeight: "84vh",
                marginLeft: "2.5vw",
                gap: 20,
                justifyContent: "center",
                alignItems: "center"
            }}>
                {/* Page Navigation Buttons */}
                <div style={{
                    // margin: '20px',
                    minWidth: "350px",
                    height: "80vh",
                    // backgroundColor : "red",
                    overflowY: "scroll"
                }}>
                    <AccordionComponent chapters={pages.sections} goToPage={goToPage} />
                </div>

                {/* PDF Viewer with iframe */}
                {/* <iframe
                    key={key}
                    src={`https://web.fttt-competition.com/reglementInterne.pdf#page=${currentPage}&zoom=80`}
                    width="100%"
                    height="100%"
                    title="PDF Viewer"
                    style={{ border: "1px solid black" }}
                /> */}

                {/* <PdfViewerComponent
                    pdfUrl="https://web.fttt-competition.com/reglementInterne.pdf"
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                /> */}
                <PdfUrlViewer url={"https://web.fttt-competition.com/reglementPrincipales.pdf"} scale={scale} windowRef={windowRef} />
            </div>
        </div>
    )
}

export default Reglement