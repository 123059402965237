
import Btn from '../../Molecules/Btn'
import NewsItem from './NewsItem'

import React, { useContext, useRef, useState, useMemo } from 'react'
import { RContext } from '../../RContext'
import { DesignContext } from '../../DesignContext';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faCircle } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { Card, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { reactLocalStorage as Ls } from 'reactjs-localstorage';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { deleteNews, updateNews } from "../actions/ajouterunjouer"
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Bars } from 'react-loader-spinner'
import PdfUrlViewer from './PdfUrlViewer';
import { GlobalWorkerOptions } from "pdfjs-dist";

// console.log(object)
GlobalWorkerOptions.workerSrc = "https://d2v5g.csb.app/pdf.worker.js";


function Reglement({ type = undefined }) {


    const dispatch = useDispatch();
    let { sex, category } = useParams();

    const { design } = useContext(DesignContext);
    const { isMedium, isSmall, isLarge, notifier, isLoggedIn } = useContext(RContext)
    const [key, setKey] = useState(0);
    let history = useHistory();

    const iframeRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = 57; // Total pages in the PDF
    const windowRef = useRef();
    const [scale, setScale] = useState(isSmall ? .65 : 1.2);
    // Function to navigate to a specific page
    const scrollToItem = (pageNum) => {
        windowRef.current && windowRef.current.scrollToItem(pageNum - 1, "start");
    };

    const goToPage = (pageNum) => {
        // setCurrentPage(pageNum);

        // // Change the key to force iframe re-render
        // setKey((prevKey) => prevKey + 1);

        scrollToItem(pageNum)

    };

    const pages = {
        "sections": [
            {
                "title": "الباب الأول : نظام عام",
                "content": [
                    {
                        "number": 1,
                        "page": 2,
                        "title": "اللجان المركزية"
                    },
                    {
                        "number": 2,
                        "page": 5,
                        "title": "الرابطات"
                    },
                    {
                        "number": 3,
                        "page": 10,
                        "title": "مجلس الجامعة"
                    },
                    {
                        "number": 4,
                        "page": 10,
                        "title": "المنح"
                    },
                    {
                        "number": 5,
                        "page": 10,
                        "title": "الإنخراطات"
                    },
                    {
                        "number": 6,
                        "page": 11,
                        "title": "الهواية"
                    },
                    {
                        "number": 7,
                        "page": 11,
                        "title": "الرز نامة  الجامعية"
                    },
                    {
                        "number": 8,
                        "page": 11,
                        "title": "استمارات الالتزام"
                    },
                    {
                        "number": 9,
                        "page": 11,
                        "title": "معاليم الالتزامات"
                    }
                ]
            },
            {
                "title": "الباب الثاني  : الإجازات وتأهل اللاعبين",
                "content": [
                    {
                        "number": 10,
                        "page": 12,
                        "title": "الإجازات"
                    },
                    {
                        "number": 11,
                        "page": 12,
                        "title": "معلوم الإجازات"
                    },
                    {
                        "number": 12,
                        "page": 12,
                        "title": "طريقة إرسال الإجازات"
                    },
                    {
                        "number": 13,
                        "page": 13,
                        "title": "التأهل"
                    },
                    {
                        "number": 14,
                        "page": 14,
                        "title": "اللاعبون الأجانب"
                    },
                    {
                        "number": 15,
                        "page": 14,
                        "title": "تنقل اللاعبين"
                    },
                    {
                        "number": 16,
                        "page": 16,
                        "title": "تصنيف اللاعبين حسب الفئة العمرية"
                    },
                    {
                        "number": 17,
                        "page": 16,
                        "title": "التباري ضمن صنف أعلى"
                    }
                ]
            },
            {
                "title": "الباب الثالث : املسابقات الوطنية ",
                "content": [
                    {
                        "number": 18,
                        "page": 17,
                        "title": "المسابقات الوطنية"
                    },
                    {
                        "number": 19,
                        "page": 17,
                        "title": "توزيع الفرق حسب الجهات"
                    },
                    {
                        "number": 20,
                        "page": 18,
                        "title": "بطولة تونس للفرق أكابر"
                    },
                    {
                        "number": 21,
                        "page": 19,
                        "title": "بطولة تونس للفرق سيدات (صنف كبريات)"
                    },
                    {
                        "number": 22,
                        "page": 19,
                        "title": "بطولة تونس للفرق للأصناف الصغرى ذكور وإناث"
                    },
                    {
                        "number": 23,
                        "page": 20,
                        "title": "كأس تونس للفرق"
                    },
                    {
                        "number": 24,
                        "page": 20,
                        "title": "طريقة اجراء مقابلات الفرق"
                    },
                    {
                        "number": 25,
                        "page": 21,
                        "title": "البطولة الوطنية للفردي رجال وسيدات"
                    },
                    {
                        "number": 26,
                        "page": 22,
                        "title": "البطولة الوطنية الممتازة للفردي"
                    },
                    {
                        "number": 27,
                        "page": 22,
                        "title": "كأس الفردي"
                    },
                    {
                        "number": 28,
                        "page": 22,
                        "title": "بطولة تونس للزوجي (رجال وسيدات ومختلط)"
                    },
                    {
                        "number": 29,
                        "page": 22,
                        "title": "الدورات الجامعية المفتوحة"
                    },
                    {
                        "number": 30,
                        "page": 23,
                        "title": "بطولة دور الشباب ونوادي الأطفال"
                    },
                    {
                        "number": 31,
                        "page": 23,
                        "title": "دورات أخرى"
                    },
                    {
                        "number": 32,
                        "page": 23,
                        "title": "النقاط"
                    },
                    {
                        "number": 33,
                        "page": 23,
                        "title": "الفصل بين الفرق أو اللآعبين"
                    }
                ]
            },
            {
                "title": "الباب الرابع : تنظيم المسابقات",
                "content": [
                    {
                        "number": 34,
                        "page": 24,
                        "title": "أوراق المقابلات"
                    },
                    {
                        "number": 35,
                        "page": 24,
                        "title": "الكرات"
                    },
                    {
                        "number": 36,
                        "page": 24,
                        "title": "تنظيم  الدورات و التحول  إلى الخارج"
                    },
                    {
                        "number": 37,
                        "page": 25,
                        "title": "إلغاء الدورات"
                    },
                    {
                        "number": 38,
                        "page": 26,
                        "title": "تأجيل المقابلات"
                    },
                    {
                        "number": 39,
                        "page": 26,
                        "title": "المرافقون و المدربون"
                    },
                    {
                        "number": 40,
                        "page": 26,
                        "title": "حكم المباراة و المندوب الجامعي"
                    },
                    {
                        "number": 41,
                        "page": 28,
                        "title": "بطاقة التعريف الجامعية"
                    }
                ]
            },
            {
                "title": "الباب الخامس : نزاعات وتأديب",
                "content": [
                    {
                        "number": 42,
                        "page": 28,
                        "title": "الغياب"
                    },
                    {
                        "number": 43,
                        "page": 29,
                        "title": "الاعتراض"
                    },
                    {
                        "number": 44,
                        "page": 30,
                        "title": "الإحترازات و  الشكايات"
                    },
                    {
                        "number": 45,
                        "page": 31,
                        "title": "العقوبات"
                    },
                    {
                        "number": 46,
                        "page": 33,
                        "title": "الغش"
                    },
                    {
                        "number": 47,
                        "page": 33,
                        "title": "لاعبو الفرق الوطنية"
                    }
                ]
            },
            {
                "title": "الباب السادس : أحكام عامة",
                "content": [
                    {
                        "number": 48,
                        "page": 34,
                        "title": "الترتيب"
                    },
                    {
                        "number": 49,
                        "page": 34,
                        "title": "ممثل النادي"
                    },
                    {
                        "number": 50,
                        "page": 34,
                        "title": "الجوائز"
                    },
                    {
                        "number": 51,
                        "page": 34,
                        "title": "ميدالية كرة الطاولة"
                    },
                    {
                        "number": 52,
                        "page": 37,
                        "title": "الهيئة الشرفية للجامعة"
                    },
                    {
                        "number": 53,
                        "page": 37,
                        "title": "تطبيق القوانين"
                    },
                    {
                        "number": 54,
                        "page": 38,
                        "title": "المصادقة على التنقيحات الخاصة بالقوانين العامة"
                    },
                    {
                        "number": 55,
                        "page": 38,
                        "title": "حالات لم يقع التعرض لها"
                    },
                    {
                        "number": 56,
                        "page": 38,
                        "title": "حالات طارئة"
                    },
                    {
                        "number": 57,
                        "page": 38,
                        "title": "التزام الفرق بالنظام الأساسي"
                    }
                ]
            }
        ]
    }


    // Render buttons for each page
    const AccordionComponent = ({ chapters, goToPage }) => {
        return (
            <Accordion defaultActiveKey="0" style={{ direction: 'rtl', textAlign: 'right' }}>
                {chapters.map((chapter, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header

                            style={{ display: 'flex', justifyContent: 'space-between', direction: 'rtl' }}
                        >
                            {/* hello */}
                            {chapter.title}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Accordion.Header>
                        <Accordion.Body>
                            {chapter.content.map((item, itemIndex) => (
                                <div key={itemIndex} style={{ marginBottom: '10px' }}>
                                    <Button
                                        variant="link"
                                        onClick={() => goToPage(item.page)}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        {`الفصل ${item.number} : ${item.title}`}
                                    </Button>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        );
    };



    return (
        <div>
            <h1 style={{ textAlign: "center", marginTop: 50, marginBottom: 10 }}>RÈGLEMENT INTÉRIEUR</h1>
            <div style={{
                display: "flex",
                flexDirection: "row-reverse",
                flexWrap: isSmall ? "wrap" : "nowrap",
                width: "95vw",
                minHeight: "84vh",
                marginLeft: "2.5vw",
                gap: 20,
                justifyContent: "center",
                alignItems: "center"
            }}>
                {/* Page Navigation Buttons */}
                <div style={{
                    // margin: '20px',
                    minWidth: "350px",
                    height: "80vh",
                    // backgroundColor : "red",
                    overflowY: "scroll"
                }}>
                    <AccordionComponent chapters={pages.sections} goToPage={goToPage} />
                </div>

                {/* PDF Viewer with iframe */}
                {/* <iframe
                    key={key}
                    src={`https://web.fttt-competition.com/reglementInterne.pdf#page=${currentPage}&zoom=80`}
                    width="100%"
                    height="100%"
                    title="PDF Viewer"
                    style={{ border: "1px solid black" }}
                /> */}

                {/* <PdfViewerComponent
                    pdfUrl="https://web.fttt-competition.com/reglementInterne.pdf"
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                /> */}
                <PdfUrlViewer url={"https://web.fttt-competition.com/reglementInterne.pdf"} scale={scale} windowRef={windowRef} />
            </div>
        </div>
    )
}

export default Reglement